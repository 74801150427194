import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_hero = _resolveComponent("home-hero")!
  const _component_home_features = _resolveComponent("home-features")!
  const _component_app_footer = _resolveComponent("app-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_home_hero),
    _createVNode(_component_home_features),
    _createVNode(_component_app_footer)
  ]))
}