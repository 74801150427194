
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      invoiceIndex: 0,
      switchTitleTime: 3000,
      switchIntervalInstance: 0,
    };
  },
  methods: {
    setNextIndex() {
      this.invoiceIndex++;
    },
  },
  computed: {
    invoiceFor() {
      const $arr = ["business", "firm", "anything"];
      // eslint-disable-next-line
      const $randomIdx = (this as any).invoiceIndex % $arr.length;
      return $arr[$randomIdx];
    },
  },
  mounted() {
    this.switchIntervalInstance = setInterval(
      this.setNextIndex,
      this.switchTitleTime
    );
  },
});
