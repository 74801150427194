
import { defineComponent } from "vue";
import HomeHero from "@/components/home/HomeHero.vue";
import HomeFeatures from "@/components/home/HomeFeatures.vue";
import AppFooter from "@/components/theme/AppFooter.vue";

export default defineComponent({
  name: "Home",
  components: { HomeHero, HomeFeatures, AppFooter },
});
