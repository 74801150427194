<template>
  <footer class="text-gray-600 bg-gray-200 body-font print-hidden">
    <div
      class="container px-5 py-8 mx-auto flex items-center place-content-center"
    >
      <h3
        class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
      >
        <span class="ml-3 text-xl">Invoi.cf</span>
      </h3>
      <p
        class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-300 sm:py-2 sm:mt-0 mt-4"
      >
        © 2020-{{ new Date().getFullYear() }} Invoi.cf - Free Invoice Service
      </p>
    </div>
  </footer>
</template>
